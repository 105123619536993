import React from "react"
import Layout from "../../components/Layout/layout"
import { Router } from "@reach/router";
import BenefitPage from "../../Views/Website/Benefits/Benefits";
import { benefitsList } from "../../Classes/Benefits";
import BenefitsList from "../../Views/Website/Benefits/BenefitsList/BenefitsList";

const BenefitsPage: React.FC = (props: any) => {
  return (
    <Layout>
      <Router basepath="/Benefits" className={"router"}>
        <PrivateRoute path="/" component={() => <BenefitsList />} />
        {
          benefitsList.filter((b, i) => i && b.Title).map((b) => {
            return <PrivateRoute path={encodeURIComponent(b.Title)} component={() => <BenefitPage Benefit={b} location={props.location.href} />} />
          })
        }
      </Router>
    </Layout>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />
}

export default BenefitsPage;