// extracted by mini-css-extract-plugin
export var Active = "Benefits-module--Active--62bac";
export var App = "Benefits-module--App--8105f";
export var Apps = "Benefits-module--Apps--6487e";
export var AppsBlock = "Benefits-module--AppsBlock--a051a";
export var Button = "Benefits-module--Button--3ab37";
export var Images = "Benefits-module--Images--c129b";
export var Links = "Benefits-module--Links--17a7b";
export var Loading = "Benefits-module--Loading--917ff";
export var Message = "Benefits-module--Message--ebb0b";
export var MoreFrom = "Benefits-module--MoreFrom--a72b7";
export var MoreFromInner = "Benefits-module--MoreFromInner--1fcdc";
export var Package = "Benefits-module--Package--cccdf";
export var PackageDoc = "Benefits-module--PackageDoc--522ae";
export var PackageInfo = "Benefits-module--PackageInfo--e03e7";
export var PackageInfoInner = "Benefits-module--PackageInfoInner--50d85";
export var PackageInner = "Benefits-module--PackageInner--4cfbe";
export var Packages = "Benefits-module--Packages--9a2fe";
export var Rating = "Benefits-module--Rating--9d0c9";
export var RatingInner = "Benefits-module--RatingInner--992f8";
export var RatingPerc = "Benefits-module--RatingPerc--a0ed9";
export var RatingStars = "Benefits-module--RatingStars--73672";
export var Ratings = "Benefits-module--Ratings--9ef36";
export var RatingsForm = "Benefits-module--RatingsForm--ed59c";
export var RatingsFormStars = "Benefits-module--RatingsFormStars--5251b";
export var RatingsList = "Benefits-module--RatingsList--af774";
export var RatingsListButton = "Benefits-module--RatingsListButton--22eec";
export var RatingsListCarousel = "Benefits-module--RatingsListCarousel--94d91";
export var RatingsListCarouselInner = "Benefits-module--RatingsListCarouselInner--c4535";
export var RatingsListItem = "Benefits-module--RatingsListItem--a1e3f";
export var RatingsOverall = "Benefits-module--RatingsOverall--94b00";
export var RatingsOverallItem = "Benefits-module--RatingsOverallItem--4fc3d";
export var RatingsOverallItemBar = "Benefits-module--RatingsOverallItemBar--62a13";
export var RatingsOverallItemBarInner = "Benefits-module--RatingsOverallItemBarInner--b9dff";
export var Right = "Benefits-module--Right--9cfe9";
export var Share = "Benefits-module--Share--9ef19";
export var Side = "Benefits-module--Side--5f00e";
export var SideInfo = "Benefits-module--SideInfo--8ad50";
export var Social = "Benefits-module--Social--e2c75";