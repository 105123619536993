import React, { useState } from 'react';
import * as styles from './Benefits.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { Helmet } from 'react-helmet';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Benefit, benefitsList } from '../../../Classes/Benefits';

interface Props {
  Benefit: Benefit;
  location: string;
}

const BenefitPage: React.FC<Props> = ({ Benefit, location }) => {
  const [copied, setCopied] = useState<boolean>(false);
  const shareURL: string = location ? `${location}` : "";

  const Copy = async () => {
    await navigator.clipboard.writeText(shareURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000)
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Benefit - {Benefit.Title}</title><meta charSet='utf-8' />
      <meta property="og:description" content={`${Benefit.Description}`} />
      <meta property="og:title" content={`PrimeHR :: Benefit - ${Benefit.Title}`} />
      <meta property="og:site_name" content="PrimeHR" />
      <meta property="og:url" content={`${location}`} />
    </Helmet>

    <Banner />
    <Breadcrumb Trail={[
      { To: `/Benefits`, Text: `Benefits` },
      { To: ``, Text: `${Benefit.Title}` }
    ]} />

    {Benefit.Title ? <div className={styles.Packages}>
      <div className={styles.Package}>
        <div className={styles.PackageInfo}>
          <div className={styles.PackageInfoInner}>
            <h1>{Benefit.Title}</h1>

            <div className={styles.Apps}>
              <div className={styles.AppsBlock}>
                {
                  <div className={styles.App}>
                    <i>
                      <FontAwesomeIcon icon={Benefit.Icon} />
                    </i>
                    <p>{Benefit.Title}</p>
                  </div>
                }
              </div>
            </div>

            <pre>{Benefit.Description}</pre>

            {Benefit.Reference ? <div className={styles.Links}>
              <a target={"_blank"} href={Benefit.Reference}>Read More From .GOV</a>
            </div> : <></>}
          </div>
        </div>
      </div>

      <div className={styles.Side}>
        <div className={`${styles.Share} ${copied ? styles.Active : ""}`}>
          <input value={shareURL} type="text" readOnly={true} />
          <button onClick={() => Copy()}>{copied ? "Copied!" : "Copy"}</button>
          <div className={styles.Social}>
            <FacebookShareButton url={shareURL}>
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
            <TwitterShareButton url={shareURL}>
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
            <LinkedinShareButton url={shareURL}>
              <LinkedinIcon size={32} round={true} />
            </LinkedinShareButton>
          </div>
        </div>

        <div className={styles.SideInfo}>
          <h4>Found This Information Useful?</h4>
          <p>If you found this Information or any of our FREE resources useful then sign up now to start using our amazing new features and tools, and take advantage of our <Link to={"/Packages/Bronze"}>Bronze</Link>, <Link to={"/Packages/Silver"}>Silver</Link> and <Link to={"/Packages/Silver"}>Gold</Link> Packages!</p>

          <Link to="/Register" className={styles.Button}>
            Create an Account
          </Link>
        </div>

        <div className={styles.MoreFrom}>
          <div className={styles.MoreFromInner}>
            <h1>Other Benefits</h1>
            <ul>
              {
                benefitsList.filter((b, i) => i && b.Title && b.Title !== Benefit.Title).map((b, i) => {
                  return <li><Link to={`/Benefits/${encodeURIComponent(b.Title)}`}>{b.Title}</Link></li>
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </div> : <></>}
  </>);
};

export default BenefitPage;
