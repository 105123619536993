import React, { useState } from 'react';
import * as styles from './BenefitsList.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import { Benefit, benefitsList } from '../../../../Classes/Benefits';
import { BenefitItem } from '../../../Portal/Benefits/Benefits';

const BenefitsList: React.FC = () => {
  const [search, setSearch] = useState<string>("");

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: What Is...</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[{ To: "", Text: "What Is..." }]} />

    <div className={styles.Freebies}>
      <div className={styles.FreebiesInner}>
        <h1>Benefits</h1>
        <p>Our PrimeHR Employment Portal allows you to build and research ideas for various benefits that your business can provide to your Employees. If you never know what Benefits to provide to your staff, start simple by offering Free Food and Drink. Then as your business grows, research bigger Benefits such as offering Company Cars and Charging stations for Electric Vehicles.
          <br />
          <br />
          Benefits are a great way to retain your Employees and make your business more attractive to potential Candidates looking at your company for a new oppurtunity.</p>

        <div className={styles.Search}>
          <div className={styles.SearchInput}>
            <i><FontAwesomeIcon icon={faSearch} /></i>
            <input type="search" placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
        </div>

        <div className={styles.Articles}>
          <div className={styles.ArticlesInner}>
            {benefitsList.filter((b, i) => i).filter(b => b.Title.toLowerCase().includes(search.toLowerCase())).length ? benefitsList.filter((b, i) => i).filter(b => b.Title.toLowerCase().includes(search.toLowerCase())).map((benefit: Benefit, i: number) => {
              return <BenefitItem Website={true} OwnData={false} Benefit={benefit} Index={i} />
            }) : <div className={styles.Message}>
              <i>
                <FontAwesomeIcon icon={faQuestionCircle} />
              </i>
              <h1>No Jobs Available</h1>
              <p>There are no available job roles to be applied for at the moment. Check back later for updates!</p>
            </div>}
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default BenefitsList;
